.about-info-wrapper{
	margin-top: 60px;
	// margin-bottom: 0px;
}

.about-info-head{
	font-weight: normal;
	font-size: 14px;
	line-height: 24px;
	margin-bottom: 45px;
	span{
		font-weight: normal;
		font-size: 24px;
		line-height: 32px;
		display: block;
	}
}

.about-info-list{
	margin-bottom: 20px;
}

.about-info-icon{
	margin-bottom: 24px;
}

.about-info-title{
	display: block;
	margin-bottom: 8px;
	font-weight: normal;
	font-size: 24px;
	line-height: 32px;
	color: $electricBlue;
}

.about-info-desc{
	display: block;
	font-weight: normal;
	font-size: 16px;
	line-height: 26px;
}

