.home-handle-wrapper{
	margin-top: 40px;
	margin-bottom: 40px;
	.page-title{
		text-align: center;
	}
}

.home-handle-list{
	text-align: center;
}

.home-handle-text{
	display: block;
	font-weight: 500;
	font-size: 18px;
	span{
		display: block;
	}
}

.home-handle-img{
	margin-bottom: 25px;
}