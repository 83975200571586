.contact-form-title{
	font-weight: normal;
	line-height: 1.35;
	color: $electricBlue;
	margin-bottom: 18px;
}

.contact-input-group{
	margin-bottom: 12px;
	display: flex;
	label{
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		color: $black;
		flex-basis: 180px;
		margin-top: 4px;
	}
	input, textarea{
		border: 1px solid #C4C4C4;
		box-sizing: border-box;
		border-radius: 5px;
		flex-basis: calc(100% - 180px);
		max-width: calc(100% - 180px);
		font-size: 14px;
		color: $black;
		padding: 6px 10px;
		&:focus{
			outline: none;
			border: 1px solid #C4C4C4;
		}
	}
	.contact-input-select{
		flex-basis: calc(100% - 180px);
		max-width: calc(100% - 180px);
		position: relative;
		&:before{
			content: '^';
			font-size: 22px;
			position: absolute;
			right: 8px;
			transform: rotate(180deg);
			top: -4px;
		}
		select{
			-webkit-appearance: none;
			appearance: none;
			background-color: transparent;
			z-index: 1;
			position: relative;
			width: 100%;
			border: 1px solid #C4C4C4;
			box-sizing: border-box;
			border-radius: 5px;
			font-size: 14px;
			color: $black;
			padding: 6px 10px;
			&:focus{
				outline: none;
				border: 1px solid #C4C4C4;
			}
		}
	}
}

.contact-form-btn{
	button{
		width: calc(100% - 180px);
		margin-left: 180px;
		background: #0F52BA;
		border-radius: 5px;
		padding: 8px 20px;
		border: none;
		color: $white;
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;	
		&:disabled{
			background-color: lightgrey;
			cursor: not-allowed;
		}
	}
}

@media (max-width: $breakpoint-desktop) {
	.contact-input-group label {
        flex-basis: 170px;
    }
	.contact-input-group{
		input, textarea, .contact-input-select{
			flex-basis: calc(100% - 170px);
			max-width: calc(100% - 170px);
		}
	}
	.contact-form-btn button{
		width: calc(100% - 170px);
		margin-left: 170px;
	}
}

@media (max-width: $breakpoint-mobile) {
    .contact-input-group label {
        flex-basis: 140px;
    }
	.contact-input-group{
		input, textarea, .contact-input-select{
			flex-basis: calc(100% - 140px);
			max-width: calc(100% - 140px);
		}
	}
	.contact-form-btn button{
		width: calc(100% - 140px);
		margin-left: 140px;
	}
}

@media (max-width: $breakpoint-smallMobile) {
	.contact-banner-wrapper:before{
		background-color: transparent;
	}
    .contact-input-group {
		display: block;
		label {
			display: block;
		}
		input, textarea, .contact-input-select{
			max-width: 100%;
			width: 100%;
		}
	}
	.contact-form-btn button{
		margin-left: 0;
		width: auto;
	}
}