.home-separator-wrapper{
	margin-top: -30px;
}

.home-separator-line{
	background: linear-gradient(90deg, rgba(192, 204, 218, 0.1) 0%, rgba(192, 204, 218, 0.6) 50.38%, rgba(192, 204, 218, 0.1) 100%);
	height: 1px;
	width: 100%;
	margin-top: 24px;
	margin-bottom: 24px;
}

.home-separator-company{
	display: flex;
    align-items: center;
    justify-content: center;
	img{
		margin-right: 50px;
		&:last-child{
			margin-right: 0px;
		}
	}
}

@media (max-width: $breakpoint-semidesktop) {
	.home-separator-wrapper{
		margin-top: -150px;
	}
}
