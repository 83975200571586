.modal-wrapper{
	.modal-dialog{
		width: 380px;
		max-width: 100%;
		margin: 30px auto;
	}
}

.modal-footer-button{
	border: none;
	padding: 6px 12px;
	font-size: 13px;
	font-weight: 500;
	line-height: 1.5;
	border-radius: 5px;
	&.cancel{
		background: $lightGray;
	}	
	&.submit{
		background-color: $electricBlue;
		color: white;
	}
}

.modal-input-group{
	margin-bottom: 10px;
	label{
		display: block;
		font-weight: 500;
		font-size: 13px;
		line-height: 24px;
		color: $black;
		margin-bottom: 3px;
	}
	input, select{
		width: 100%;
		border: 1px solid #C4C4C4;
		box-sizing: border-box;
		border-radius: 5px;
		font-size: 14px;
		color: $black;
		padding: 6px 10px;
		&:focus{
			outline: none;
			border: 1px solid #C4C4C4;
		}
	}
	.modal-input-select{
		position: relative;
		select{
			-webkit-appearance: none;
			appearance: none;
			background-color: transparent;
			z-index: 1;
			position: relative;
		}
		&:before{
			content: '^';
			font-size: 22px;
			position: absolute;
			right: 8px;
			transform: rotate(180deg);
			top: -4px;
		}
	}
}