.navbar{
	margin-top: 8px;
	margin-bottom: 8px;
}

.navbar-brand{
	margin-right: 60px;
}

.navbar-nav .nav-link{
	font-family: 'Manrope';
	font-weight: bold;
	font-size: 14px;
	line-height: 20px;
}

.navbar-light .navbar-nav .nav-link{
	color: $black;
}

.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .nav-link.active{
	color: $black;
}

.nav-lang{
	margin-right: 40px;
	button{
		padding: 0;
		border: none;
		background-color: transparent;
		margin-right: 15px;
		&:last-child{
			margin-right: 0px;
		}
		&.disabled{
			filter: grayscale(100%);
			opacity: 0.3;
		}
	}
}

.navbar-light .navbar-nav .nav-link.navbar-buybtn{
	margin-right: 40px;
	border-radius: 8px;
	background-color: $electricBlue;
	font-weight: bold;
	font-size: 14px;
	color: $white;
	padding: 10px 22px;
	border: none;
	line-height: 20px;
}

.navbar-wabtn{
	padding: 0;
	border: none;
	background-color: transparent;
}

.navbar-expand-lg .navbar-nav .dropdown-menu{
	padding: 0;
	font-size: 14px;
	border: none;
	.dropdown-item{
		padding: 0.25rem 1rem;
	}
	.dropdown-item:hover, .dropdown-item:focus{
		color: rgba(0, 0, 0, 0.7);
		background-color: transparent;
	}
}

.dropdown-item.active, .dropdown-item:active{
	background-color: transparent;
	font-weight: bold;
	color: #16181b;
}

@media (min-width: $breakpoint-semidesktop) {
	.navbar-expand-lg .navbar-nav .dropdown-menu{
		border: 1px solid rgba(0, 0, 0, 0.15);
	}
}

@media (max-width: $breakpoint-semidesktop) {
	.navbar-nav.align-items-center{
		align-items: flex-start !important;
	}
	.nav-lang{
		margin-bottom: 12px;
	}
	.navbar-buybtn{
		margin-bottom: 10px;
	}
}