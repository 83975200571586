.about-detail-wrapper{
	padding-top: 60px;
	padding-bottom: 60px;
	text-align: center;
}

.about-detail-list{
	padding-left: 0px;
	list-style: none;
}

.about-detail-listing{
	padding-left: 0px;
    list-style: none;
    display: flex;
    justify-content: center;
	position: relative;
	&:after{
		// content: '';
		// background-color: $electricBlue;
		// height: 1px;
		// position: absolute;
		// width: calc(75% - 100px);
		// bottom: -40px;
	}
	li{
		flex: 25% 1 1;
		max-width: 25%;
		.about-detail-card{
			background-color: $red;
			font-weight: 500;
			font-size: 20px;
			line-height: 30px;
			color: $white;
			padding: 25px;
			height: 100%;
			border-radius: 5px;
			margin-left: 20px;
			margin-right: 20px;
			text-align: center;
			position: relative;
			&:before{
				content: '';
				position: absolute;
				bottom: -5px;
				left: 50%;
				transform: translateX(-50%);
				width: 0;
				height: 0;
				border-left: 20px solid transparent;
				border-right: 20px solid transparent;
				border-top: 15px solid $red;
			}
			&:after{
				content: '';
				bottom: -40px;
				width: 50%;
				background-color: transparent;
				position: absolute;
				border-bottom-left-radius: 70%;
				left: 49%;
				height: 60px;
				border-bottom: 1px solid $electricBlue;
			}
		}
		&:first-child{
			.about-detail-card{
				margin-left: 0px;
			}
		}
		&:nth-child(3){
			.about-detail-card{
				&:after{
					border-bottom-right-radius: 70%;
					right: 49%;
					border-bottom-left-radius: 0;
					left: auto;
				}
			}
		}
		&:last-child{
			.about-detail-card{
				margin-right: 0px;
				&:after{
					border-bottom-right-radius: 70%;
					right: 49%;
					border-bottom-left-radius: 0;
					left: auto;
				}
			}
		}
	}
}

.about-detail-listing.bottom{
	&:after{
		top: -40px;
	}
	li{
		.about-detail-card{
			&:before{
				top: -5px;
				border-left: 20px solid transparent;
				border-right: 20px solid transparent;
				border-bottom: 15px solid $red;
				border-top: none;
			}
			&:after{
				top: -40px;
				bottom: auto;
				border-bottom-left-radius: 0;
				border-top-left-radius: 70%;
				border-top: 1px solid $electricBlue;
				border-bottom: none;
			}
		}
		&:nth-child(3){
			.about-detail-card{
				&:after{
					border-top-right-radius: 70%;
					border-top-left-radius: 0;
				}
			}
		}
		&:last-child{
			.about-detail-card{
				&:after{
					border-top-right-radius: 70%;
					border-top-left-radius: 0;
				}
			}
		}
	}

	
}

.about-detail-logo{
	margin-top: 85px;
	margin-bottom: 85px;
	position: relative;
	&:before{
		content: '';
		position: absolute;
		height: 85px;
		top: -45.5px;
		left: calc(25% - 25px);
		width: calc(25% + 25px);
		border-top: 1px solid $electricBlue;
		border-top-right-radius: 25px;
	}
	&:after{
		content: '';
		position: absolute;
		width: calc(25% + 25px);
		height: 85px;
		top: -45.5px;
		left: 50%;
		border-top: 1px solid $electricBlue;
		border-top-left-radius: 25px;
	}
	span{
		position: relative;
		width: 100%;
		display: block;
		&:before{
			content: '';
			position: absolute;
			width: calc(25% + 25px);
			height: 85px;
			top: -39px;
			left: calc(25% - 25px);
			border-bottom: 1px solid $electricBlue;
			border-bottom-right-radius: 25px;
		}
		&:after{
			content: '';
			position: absolute;
			width: calc(25% + 25px);
			height: 85px;
			top: -39px;
			left: 50%;
			border-bottom: 1px solid $electricBlue;
			border-bottom-left-radius: 25px;
		}
	}
}

@media (max-width: $breakpoint-semidesktop) {
	.about-detail-listing li .about-detail-card{
		padding: 15px 10px;
		padding-bottom: 20px;
		line-height: 1.5;
		font-size: 16px;
		margin-left: 10px;
		margin-right: 10px;
	}
	.about-detail-logo{
		&:before{
			left: calc(25% - 15px);
			width: calc(25% + 15px);
			border-top-right-radius: 15px;
		}
		&:after{
			width: calc(25% + 15px);
			border-top-left-radius: 15px;
		}
		span{
			&:before{
				left: calc(25% - 15px);
				width: calc(25% + 15px);
				border-bottom-right-radius: 15px;
				top: -39px;
			}
			&:after{
				width: calc(25% + 15px);
				border-bottom-left-radius: 15px;
				top: -39px;
			}
		}
	}
}


@media (max-width: $breakpoint-tablet) {
	.about-detail-listing li .about-detail-card {
		padding: 10px 5px;
		padding-bottom: 10px;
		font-size: 12px;
		margin-left: 5px;
		margin-right: 5px;
		word-break: break-word;
		&:after{
			bottom: -25px;
			height: 35px;
			border-bottom-left-radius: 90%;
		}
	}
	.about-detail-listing.bottom li .about-detail-card:after{
		top: -25px;
	}
	.about-detail-logo{
		margin-top: 45px;
		margin-bottom: 45px;
		img{
			width: 190px;
		}
		&:before{
			top: -20.5px;
			border-top-right-radius: 8px;
			height: 25px;
		}
		&:after{
			top: -20.5px;
			border-top-left-radius: 8px;
			height: 25px;
		}
		span{
			&:before{
				top: -4.5px;
				border-bottom-right-radius: 8px;
				height: 25px;
			}
			&:after{
				top: -4.5px;
				border-bottom-left-radius: 8px;
				height: 25px;
			}
		}
	}
}