.home-real-wrapper{
	padding-top: 60px;
	padding-bottom: 60px;
	position: relative;
	background-color: $tosca;
	.container{
		&:before{
			content: "";
			position: absolute;
			background-image: url('../img/Icon_quotemark.png');
			background-size: cover;
			background-repeat: no-repeat;
			display: inline-block;
			width: 143px;
			height: 120px;
			left: 10%;
		}
	}
	.page-title{
		margin-bottom: 8px;
	}
}

.home-real-content{
	padding-top: 70px;
	padding-left: 15%;
}

.real-content-card{
	max-width: 420px;
	background: $white;
	box-shadow: 0px 10px 20px rgba(41, 41, 42, 0.07);
	border-radius: 8px;
	padding: 32px;
	margin-bottom: 25px;
	&.right{
		margin-left: auto;
		max-width: 350px;
	}
	.real-card-title{
		color: $red;
		font-weight: 800;
		line-height: 40px;
		&.solution{
			color: $electricBlue;
		}
	}
	.real-content-desc{
		font-weight: normal;
		font-size: 14px;
		line-height: 24px;
		color: $black;
	}
	ul{
		padding-left: 20px;
		li{
			padding-left: 10px;
		}
	}
	img{
		max-width: 100%;
	}
}

@media (max-width: $breakpoint-tablet) {
	.home-real-wrapper{
		.container{
			&:before{
				width: 60px;
				height: 50px;
				left: 20px;
			}
		}
	}
	.home-real-content{
		padding: 0;
		padding-top: 40px;
	}
	.real-content-card{
		max-width: 100%;
		&.right{
			margin-left: 0;
			max-width: 100%;
		}
	}
}
