.home-banner-wrapper{
	background-image: url('../img/Background.png');;
	background-repeat: no-repeat;
	background-size: cover;
	padding-top: 50px;
    padding-bottom: 320px;
    background-position: center 85%;
	.col-lg-8, .col-lg-4{
		position: static;
	}
}

.home-banner-content{
	display: flex;
	justify-content: center;
    flex-direction: column;
}

.home-banner-head{
	font-weight: 800;
	line-height: 40px;
}

.home-banner-title{
	font-weight: 800;
	font-size: 44px;
	line-height: 64px;
}

.home-banner-desc{
	font-weight: 500;
	line-height: 30px;
	margin-bottom: 30px;
}

.home-banner-btn{
	border-radius: 8px;
	background-color: $electricBlue;
	font-weight: bold;
	font-size: 18px;
	line-height: 30px;
	color: $white;
	padding: 10px 22px;
	border: none;
	margin-bottom: 40px;
	display: block;
	text-align: center;
    width: fit-content;
	&:hover, &:focus{
		color: $white;
		text-decoration: none;
	}
}

.home-banner-img{
	max-width: 100%;
	position: relative;
	width: auto;
}

#text{
	width: 150px;
	height: 150px;
	border-radius: 50%;
	background-color: red;
	position: relative;
	&:after{
		width: 30px;
		height: 10px;
		background-color: blue;
		content: '';
		top: 50%;
		transform: translateY(-50%);
		right: 0;
		position: absolute;
	}
}

.home-banner-imgwrapper{
    text-align: center;
    overflow: hidden;
}

.home-banner-imgwrapper .carousel-initialized {
    width: 328px;
    height: 488px;
}

.home-banner-imgwrapper .carousel-item{
	transition: none;
    transform: none !important;
}

@media (max-width: $breakpoint-semidesktop) {
	.home-banner-wrapper{
		padding-bottom: 180px;
		background-position: 40.5%;
	}
}