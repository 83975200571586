.modal-response-wrapper{
	.modal-content{
		border: none;
		box-shadow: 1px 3px 6px rgba(0,0,0,0.2);
	}
}
.modal-header{
	border: none;
	padding: 10px 16px;
	.modal-title{
		font-size: 16px;
		color: white;
	}
	&.modal-header-error{
		background-color: $red;
	}
	&.modal-header-success{
		background-color: $electricBlue;
	}
}

.modal-body-content{
	font-size: 14px;
}