.home-feature-wrapper{
	padding-top: 40px;
	padding-bottom: 80px;
	position: relative;
	&:before{
		position: absolute;
		content: '';
		width: 15%;
		border-radius: 50%;
		background-color: $red;
		left: 15%;
		top: 55%;
		transform: translateY(-50%);
		padding-bottom: 15%;
	}
	&:after{
		right: 3%;
		position: absolute;
		content: '';
		padding-bottom: 25%;
		width: 25%;
		border-radius: 50%;
		background-color: $electricBlue;
		top: 55%;
		z-index: -1;
		transform: translateY(-50%);
	}
}

.home-feature-list{
	.col-lg-3{
		margin-bottom: 20px;
	}
}

.home-feature-card{
	background: #FFFFFF;
	box-shadow: 0px 10px 20px rgba(41, 41, 42, 0.07);
    border-radius: 8px;
    padding: 24px;
	height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.home-feature-text{
	font-weight: bold;
	font-size: 18px;
	line-height: 28px;
	span{
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
	}
}

.home-feature-img{
	display: block;
	margin-top: 15px;
}
