@import "./ContactForm.scss";

.contact-banner-wrapper{
	background-color: $tosca;
	position: relative;
	&:before{
		content: '';
		background-image: url('../img/Background_Contact.png');
		background-repeat: no-repeat;
		height: 100%;
		width: 50%;
		left: 0;
		top: 0;
		position: absolute;
		background-position: 70% top;
		background-size: cover;
	}
}

.contact-banner-card{
	background: #FFFFFF;
	border-radius: 5px;
	padding: 15px 30px;
	width: 300px;
	max-width: 100%;
	margin-top: 60px;
	margin-bottom: 50px;
}

.contact-banner-address{
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	margin-bottom: 8px;
	span{
		font-weight: bold;
		line-height: 20px;
		display: block;
	}
}

.contact-location-link{
	display: flex;
	align-items: center;
	color: $electricBlue;
	text-decoration: none;
	cursor: pointer;
	&:hover{
		text-decoration: none;
	}
}

.contact-location-icon{
	margin-right: 8px;
}

.contact-banner-form{
	margin-top: 30px;
	margin-bottom: 30px;
	background: $white;
	box-shadow: 0px 30px 40px rgba(212, 217, 232, 0.2);
	padding: 20px 45px;
}

@media (min-width: $breakpoint-semidesktop) {
	.contact-banner-wrapper{
		min-height: calc(100vh - 355px);
		display: flex;
		align-items: center;
	}
}

@media (max-width: $breakpoint-semidesktop) {
	.contact-banner-wrapper{
		&:before{
			width: 100%;
			height: 270px;
		}
	}
}

@media (max-width: $breakpoint-mobile) {
	.contact-banner-wrapper{
		&:before{
			background-color: $white;
		}
	}
}

@media (max-width: $breakpoint-smallMobile) {
	.contact-banner-wrapper{
		&:before{
			content: none;
		}
	}
	.contact-banner-form{
		margin-bottom: 0px;
		padding: 20px;
	}
}

