//color
$black: #18191F;
$white: #FFFFFF;
$electricBlue: #0F52BA;
$red: #B12024;
$tosca: #D5FAFC;
$lightGray: #EEEFF4;

//breakpoint
$breakpoint-smallMobile: 450px;
$breakpoint-mobile: 599px;
$breakpoint-tablet: 767px;
$breakpoint-semidesktop: 992px;
$breakpoint-desktop: 1200px;

//font
@font-face {
    font-family: 'Manrope';
    src: url('./assets/font/Manrope-Regular.woff2') format('woff2'),
        url('./assets/font/Manrope-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}