.footer{
	background-color: $electricBlue;
	padding-top: 40px;
	padding-bottom: 40px;
	color: $white;
}

.footer-logo{
	margin-top: 15px;
}

.footer-nav{
	.nav-link{
		color: white;
		padding: 24px;
		font-weight: bold;
		font-size: 14px;
		line-height: 20px;
		&:first-child{
			padding-left: 0px;
		}
	}
}

.footer-copyright{
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	margin-bottom: 30px;
}

.footer-info{
	h3{
		font-weight: 500;
		font-size: 20px;
		line-height: 30px;
	}
}

.footer-info-list{
	list-style: none;
    padding-left: 0;
	li{
		display: flex;
		margin-bottom: 20px;
	}
}

.footer-info-icon{
	margin-right: 10px;
	width: 20px;
	text-align: center;
}